<template>
  <AntRow class="row" justify="center">
    <AntCol>
      <AntResult
        title="Welcome to TesterPortal"
        subTitle="Please click on the menu on the left to enter the paging."
      >
        <template #icon>
          <SmileIcon />
        </template>
      </AntResult>
    </AntCol>
  </AntRow>
</template>

<script>
import {
  Row as AntRow,
  Col as AntCol,
  Result as AntResult,
} from 'ant-design-vue'

import SmileIcon from '@ant-design/icons-vue/lib/icons/SmileTwoTone'

export default {
  name: 'EnterGame',
  components: {
    AntRow,
    AntCol,
    AntResult,
    SmileIcon,
  },
  props: {
    selectedSourceLine: Object,
    loginPlayerData: Object,
    requestResponseData: Object,
    onChangeLoginPlayerData: Function,
  },
  setup() {
  },
}
</script>
